import React from "react";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import EventCard from "../components/EventCard";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import { gaEvent } from "../helpers";
import Heading from "../components/Heading";
import HeroHeader from "../components/HeroHeader";

import WfLogo from "../../static/img/WinterFEAT-White-Horizontal.svg";
import { useModal } from "../../provider";
import FAQListItem from "../components/FAQList/FAQListItem";
import InstagramFeed from "../components/InstagramFeed/index";

const winter_feat_events = [
  {
    eventName: "North & South",
    eventLocation: "Bradfield Park",
    date: new Date(2022, 5 - 1, 28),
  },
  {
    eventName: "Team Colour Session",
    eventLocation: "All Locations",
    date: new Date(2022, 6 - 1, 4),
  },
  {
    eventName: "Team Colour Session",
    eventLocation: "All Locations",
    date: new Date(2022, 6 - 1, 14),
  },
  {
    eventName: "Winter Solstice",
    eventLocation: "All Locations",
    date: new Date(2022, 6 - 1, 21),
  },
  {
    eventName: "FEAT Team Colour Run",
    eventLocation: "Location to be confirmed",
    date: new Date(2022, 6 - 1, 26),
  },
  {
    eventName: "NAIDOC Week Begins",
    eventLocation: "All Locations",
    date: new Date(2022, 7 - 1, 4),
  },
  {
    eventName: "Team Colour Session",
    eventLocation: "All Locations",
    date: new Date(2022, 7 - 1, 7),
  },
  {
    eventName: "Trivia Night",
    eventLocation: "All Locations",
    date: new Date(2022, 7 - 1, 13),
  },
  {
    eventName: "Team Colour Session",
    eventLocation: "All Locations",
    date: new Date(2022, 7 - 1, 20),
  },
  {
    eventName: "Obstacle Course",
    eventLocation: "Centennial Park",
    date: new Date(2022, 7 - 1, 24),
  },
  {
    eventName: "City2Surf",
    eventLocation: "Hyde Park to Bondi Beach",
    date: new Date(2022, 8 - 1, 8),
  },
  {
    eventName: "FEAT Dip",
    eventLocation: "Balmoral Beach",
    date: new Date(2022, 8 - 1, 21),
  },
  {
    eventName: "Winter FEAsT",
    eventLocation: "Venue to be confirmed",
    date: new Date(2022, 8 - 1, 21),
  },
];

const WinterFEATPage = ({ data }) => {
  const { wfPost: post } = data;
  const modal = useModal();

  const freeTrialBanner = (
    <div className="p-4 py-12 sm:p-16  bg-feat-lightgrey-600 text-center">
      <div>
        <Heading level={2} color={"white"}>
          JOIN FEAT FITNESS
        </Heading>
        <div className="max-w-xl text-center text-white m-auto">
          <p className="pt-8">
            To join FEAT Fitness and our Winter FEAT competition, start with
            your <b>no obligation free week trial</b> today!
          </p>
          <p className="pt-8">
            Simply complete our Free Trial form, and one of our friendly
            trainers will contact you to get started.
          </p>
        </div>

        <div className="mt-8 mb-2 m-auto">
          <a
            href="#"
            onClick={() => {
              gaEvent("Button", "FreeTrial", "free trial - winter feat middle");
              modal.openFreeTrialModal();
            }}
            className="uppercase whitespace-nowrap items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-feat-orange hover:bg-feat-orange-200"
          >
            START YOUR FREE TRIAL
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        meta_title={post.frontmatter.meta_title}
        meta_desc={post.frontmatter.meta_description}
        slug={post.frontmatter.slug}
      />
      <HeroHeader
        title={post.frontmatter.title}
        svgLogo={WfLogo}
        logoTagline="84 Sunrises, together"
        background_image={data?.image}
        options={[]}
      />
      <main className="section">
        <div className="p-4 py-12 sm:p-16 mx-4 sm:mx-16 flex flex-col md:flex-row md:space-x-6">
          <div className="flex-7 mb-8 lg:mb-12">
            <p className="text-center pt-8 text-base text-feat-darkgray-300 max-w-4xl m-auto">
              At FEAT we embrace the outdoors. We thrive in an environment that
              challenges us to get comfortable with the uncomfortable. And best
              of all, we cheer each other on in the process. No matter the
              season we’re in!
            </p>
            <p className="text-center pt-8 text-base text-feat-darkgray-300 max-w-4xl m-auto">
              There is so much in our lives designed to make everything easier
              and more comfortable. We have such amazing technology at our
              fingertips that we can literally click a button and a three-course
              meal will be driven to our door. We can sit on our couch and tell
              the computer to turn the lights on. We’re constantly conditioned
              to seek comfort. Which is great - who doesn’t love a faster/easier
              way to accomplish things in their busy schedules? But…by getting
              outside your comfort zone (literally), you can challenge your body
              and mind to reach new levels.
            </p>
            <p className="text-center pt-8 text-lg text-feat-darkgray-300 max-w-4xl m-auto">
              <em>That’s what Winter FEAT is all about!</em>
            </p>
            <p className="text-center pt-8 text-base text-feat-darkgray-300 max-w-4xl m-auto">
              Training through winter and committing to getting to each session
              you said you would attend, builds a solid foundation for our
              physical and mental resolve which translates into the rest of our
              lives.
            </p>
          </div>
          <div className="flex-3 mt-12">
            <GatsbyImage
              image={
                post.frontmatter.image_wf_dip.childImageSharp.gatsbyImageData
              }
              alt="Winter FEAT Dip - the Final Event"
              className="w-full object-contain"
            />
          </div>
        </div>

        <div className="p-4 py-12 sm:p-16  bg-feat-blue text-center">
          <div className="flex flex-col-reverse md:flex-row md:space-x-6">
            <div className="flex-3 mt-12 text-center">
              <GatsbyImage
                image={
                  post.frontmatter.image_wf_obs.childImageSharp.gatsbyImageData
                }
                alt="Winter FEAT Obstacle Course"
              />
            </div>

            <div className="flex-7 mb-8 lg:mb-12 text-center text-base text-white max-w-4xl m-auto">
              <Heading level={2} color={"white"}>
                What is Winter FEAT?
              </Heading>
              <p className="pt-8">
                Winter FEAT is a 12-week challenge where you and your training
                community get to amass points through your commitment and
                follow-through. Winter FEAT is centred around our core
                principles of <strong>COMMUNITY</strong> and{" "}
                <strong>FUN</strong>.
              </p>
              <p className="pt-8">
                Throughout winter, we bring endless amounts of play down to the
                park to our training sessions. We get our bodies moving, we
                participate in challenges, and we support each other every step
                of the way.
              </p>
              <p className="pt-8">
                Training through the cooler part of the year isn’t always easy.
                It’s darker when your alarm goes off, every part of your brain
                might tell you to stay inside, stay comfortable. When you commit
                to Winter FEAT you never take the easy option. And because it’s
                not easy, it’s truly fulfilling and fun, whilst the rewards for
                your physical, mental and spiritual health are bountiful.
              </p>
            </div>
          </div>
        </div>

        {freeTrialBanner}
        <div className="p-4 py-12 sm:p-16 text-center">
          <div className="">
            <Heading level={2}>COMPETITION DATES</Heading>
            <Heading level={3} className="mt-8">
              28 May 2022
            </Heading>
            <p className="pt-4">
              Winter FEAT 2022 begins! We kick off the competition with our{" "}
              <strong>North&nbsp;&amp;&nbsp;South&nbsp;Session</strong> starting
              in Bradfield Park.
            </p>
            <Heading level={3} className="mt-8">
              20 August 2022
            </Heading>

            <p className="pt-4">
              We send off the Winter FEAT competition with our{" "}
              <strong>Winter&nbsp;FEAT&nbsp;Dip</strong> in Balmoral in the
              morning.
            </p>
            <Heading level={3} className="mt-8">
              27 August 2022
            </Heading>

            <p className="pt-4">
              We celebrate all our efforts with our{" "}
              <strong>Winter&nbsp;FEAsT</strong>.
            </p>
            <p className="pt-8">
              <em>
                There are many other events during the competition listed below!
              </em>
            </p>
          </div>
          <div className="mt-8">
            <Heading level={2}>PRIZES</Heading>

            <Heading level={3} className="mt-8 italic" color="feat-orange">
              6 MONTHS FREE TRAINING
            </Heading>
            <p className="pt-8">
              At the end of the Winter FEAT competition, there will be 2 prizes
              of 6 months free training!{" "}
            </p>
            <p className="p-8 max-w-lg m-auto">
              There are two ways to win one of the free training prizes:
              <ol className="list-decimal text-left pt-2">
                <li>Be crowned the Winter FEAT winner; or</li>
                <li>
                  Win the lucky draw at the FEAsT, everyone in the winning team
                  plus the top 2 performers from all teams will be in the
                  running to win this prize.
                </li>
              </ol>
            </p>
          </div>
        </div>

        <div className="p-4 py-12 sm:p-16  bg-feat-blue text-white text-center">
          <div className=" ">
            <Heading level={2} color={"white"}>
              WINTER FEAT SCORING
            </Heading>

            <p className="pt-8">
              To be crowned the winner of the Winter FEAT competition you need
              to collect as many points as you can! Points are earned through
              your commitment to yourself and training throughout the 12 weeks.
            </p>
            <p className="pt-8">
              Reply to the Sunday Text before 4 pm - <strong>1 point</strong>
              <br />
              Attend all the sessions you said you would -{" "}
              <strong>5 points</strong>
              <br />
              Attending an outdoor wet weather session -{" "}
              <strong>2 points</strong>
              <br />
              Attending social events or mega sessions -{" "}
              <strong>1 point</strong>
              <br />
              Attending a regular Saturday session - <strong>1 point</strong>
              <br />
              Attending a team colour event in your teams colour -{" "}
              <strong>1 point</strong>
              <br />
              Bring a friend for a two-week trial - <strong>5 points</strong>
              <br />
              Bring a friend to a '<em>Friends Friday Session</em>' -{" "}
              <strong>1 point</strong>
              <br />
            </p>
          </div>
        </div>

        {/* <section className="section section--gradient content parallax trainers-parallax">
          <div className="container">&nbsp;</div>
        </section>
        <section className="section section--gradient has-background-info content">
          <div className="container has-text-centered">
            <div className="columns">
              <div className="column is-8 is-offset-2 has-text-centered">
                <h3 className="title is-3">THE JOKER</h3>
              </div>
            </div>
            <div className="columns">
              <div className="column is-4 is-offset-2 has-text-centered">
                <p className="has-text-centered">
                  This year we have a new addition to Winter&nbsp;FEAT!
                  <br />
                  <b>The Joker.</b>
                </p>
                <p className="has-text-centered">
                  In your WinterFEAT Welcome Pack, you'll receive a Joker Card.
                  This card is a chance to rebook one of your missed sessions
                  and still get the full 5 points that week!
                </p>
                <p className="has-text-centered">
                  Use it wisely because you have to surrender it when you use
                  it, and it can't be shared with other teammates. <br />
                  Just give it to your trainer and let them know that you'll be
                  playing the Joker.
                </p>
              </div>
              <div className="column is-offset-1 is-2 has-text-centered">
                <img src="/img/WF-JokerCard.png" alt="Winter FEAT Joker Card" />
              </div>
            </div>
          </div>
        </section> */}

        <div className="p-4 py-12 sm:p-16 text-center">
          <Heading level={2}>Winter FEAT EVENTS</Heading>
          <div className="text-center text-3xl text-feat-darkgray italic mt-4">
            EVENTS TO BE ANNOUNCED SOON
          </div>
          {/* <div className="is-flex is-flex-wrap-wrap is-flex-direction-row	"> */}
          {/* {winter_feat_events.map((e) => (
                    <EventCard
                      eventName={e.eventName}
                      eventLocation={e.eventLocation}
                      date={e.date}
                    />
                  ))} */}
          {/* </div> */}
        </div>
        {freeTrialBanner}

        <div className="p-4 py-12  sm:p-16   text-center">
          <Heading level={2}>FAQs</Heading>

          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            <FAQListItem
              question={"Can I enter Winter FEAT at any time?"}
              answer={
                <p>
                  If you're already a member with FEAT Fitness, you'll be
                  entered into the Winter FEAT competition automatically. If
                  you're a new member, you'll be automatically entered into
                  Winter FEAT when you join.
                </p>
              }
            />
            <FAQListItem
              question={"How are the points per team calculated?"}
              answer={
                <p>
                  We take all the points from people in that colour, and every
                  week we normalise the data so that points are averaged across
                  the number of active participating members that week. So if
                  you're away for a ski trip or school holidays, don't worry
                  you're not going to let your team down! Just make sure you let
                  your trainer know and fill in a{" "}
                  <Link to="/feat-members/freeze-membership/">freeze form</Link>
                  .
                  <br />
                  At the end of the 12 weeks, we'll add up all of these weekly
                  normalised scores and produce a winning team!
                </p>
              }
            />

            <FAQListItem
              question={
                "Does it matter if the teams don't have the same size of people per location"
              }
              answer={
                <p>
                  Nope! The scores are normalised week on week to average out
                  the score based on the number of active members that week.
                </p>
              }
            />

            <FAQListItem
              question={
                "I want to rebook my session, do I still get my 5 points if I hit the same number of sessions?"
              }
              answer={
                <p>
                  We support you doing what you said you'd do on our Sunday
                  text. Rebooking a session gets you alot of brownie points and
                  some high fives! But you need to hit those days to get the
                  full 5 points.
                  <br />
                  <em>
                    <b>HOWEVER!</b>
                  </em>
                  <br />
                  This year we have introduced the <em>Joker Card</em>! You can
                  surrender the card to be forgiven for one of these rebooked
                  sessions!
                </p>
              }
            />

            <FAQListItem
              question={
                <>
                  How do I use the <em>Joker Card</em> in my welcome pack?
                </>
              }
              answer={
                <p>
                  This card is a chance to rebook one of your missed sessions
                  and still get the full 5 points that week! Use it wisely
                  because you have to surrender it when you use it, and it can't
                  be shared with other teammates. Just give it to your trainer
                  and let them know that you'll be playing the Joker.
                </p>
              }
            />

            <FAQListItem
              question={"What colour does each location have?"}
              answer={
                <p>
                  Great Question! Luckily you'll find out shortly! All colours
                  and shirts will be revealed at the end of our{" "}
                  <b>North & South Session</b>. So don't miss this one!
                </p>
              }
            />

            <FAQListItem
              question={
                "Who can answer any other questions about Winter FEAT I have?"
              }
              answer={
                <p>
                  If you have any specific question, just contact your trainer!
                  They love to answer these questions, Winter FEAT is their
                  favourite time of the year.
                  <br />
                  If you're a new starter, fill in a{" "}
                  <Link to="/freetrial">Free Trial Form</Link>, and we'll get in
                  touch with you to answer any questions you have!
                </p>
              }
            />
          </dl>
          <div className="mt-12 italic">
            If you still have questions, you can also check out our general{" "}
            <Link to="/faqs" className="underline text-feat-blue">
              faqs page
            </Link>
          </div>
        </div>
        <InstagramFeed />
        {/* <section className="section section--gradient testimonial-section">
        <div>
          <div className="container">
            <h2 className="has-text-weight-semibold is-size-2 has-text-centered">
              Hear from our community
              <br />
            </h2>
          </div>
          {post.frontmatter.testimonials.map((t) => {
            return t.quote;
          })}
        </div>
      </section> */}
      </main>
    </Layout>
  );
};

export default WinterFEATPage;

export const winterFEATPageQuery = graphql`
  query WinterFEATPage($id: String!) {
    wfPost: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        image {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
        image_wf_dip {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
        image_wf_sunrise {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
        image_wf_obs {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
        testimonials {
          quote
        }
      }
    }
    image: file(relativePath: { eq: "WF-Header.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
